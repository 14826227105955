// _createPage(actions, `/target-resources-country`, { data: dataForTumbon, view: 'province' })
// _createPage(actions, `/target-resources-sungkud`, { data: dataForSungkud, view: 'sungkud' })
// import Chart from '@src/components/Chart';
// import RefDataDate from '@src/components/RefDataDate';
import * as React from 'react';
import Layout from '@src/components/Layout';
import Chart from '@src/components/Chart';
import RefDataDate from '@src/components/RefDataDate';
import { navigate } from 'gatsby'
// import './index.css';
import Filter from '@src/components/Filter';
import ModalTabelByProvicce from '@src/components/modal/ModalTabelByProvicce';
import ModalTabelBySungkud from '@src/components/modal/ModalTabelBySungkud';
import ModalQinfo from '@src/components/modal/ModalQinfo';
import DrilldownModal from '@src/components/DrilldownModal';

import confixColumnsTable from '@src/confixTable';
import ProgressView from '@src/components/ProgressView';
import ProgressProbView from '@src/components/ProgressProbView';
import Banner from '@src/components/Banner';
import api from '@src/util/api';

// image
import ImageBannerd from '/images/resources_teacher_bannerd.png'
import ImageBG from '/images/bg.png'

import {
  InfoCircleOutlined,
  TableOutlined
} from '@ant-design/icons';

const BarView = ({ percent, color }) => {
  return <div className="h-4 rounded-full" style={{ backgroundColor: color, width: `${percent ? percent : 0}%` }}></div>
}

const mapData = (data, filter) => {
  console.log(" data mapData>> ", data)
  const _filterData = (x) => {
    if (filter.view === 'sungkud') {
      if (filter.type === 'sungkud') return (x.sungkud === filter.sungkud)
      else if (filter.type === 'area') return (x.sungkud === filter.sungkud && x.area === filter.area)
      else return true
    } else {
      if (filter.type === 'province') return (x.province_name === filter.province_name)
      else if (filter.type === 'city') return (x.province_name === filter.province_name && x.city_name === filter.city_name)
      else if (filter.type === 'tumbon') return (x.province_name === filter.province_name && x.city_name === filter.city_name && x.tumbon_name === filter.tumbon_name)
      else return true
    }
  }

  let result = {
    total: 0,
    sizeS: 0,
    sizeL: 0,
    sizeX: 0,
    sizeXL: 0,
    student_total: 0,
    teacher_total: 0,
    teacher_total_f: 0,
    teacher_total_m: 0,

    desktop_computer: 0,
    notebook: 0,

    desktop_computer_for_manage_can_be_used: 0,
    desktop_computer_for_learning_can_be_used: 0,
    notebook_for_manage_can_be_used: 0,
    notebook_for_learning_can_be_used: 0,

    school_has_electricity: 0,
    school_not_electricity: 0,
    school_has_electricity_service_area: 0,
    school_not_electricity_service_area: 0,

    agricultural_chemistry_air_problem: 0,
    factory_air_problem: 0,
    traffic_air_problem: 0,
    garbage_air_problem: 0,

    agricultural_chemistry_water_problem: 0,
    factory_water_problem: 0,
    traffic_water_problem: 0,
    garbage_water_problem: 0,

    agricultural_chemistry_noise_problem: 0,
    factory_noise_problem: 0,
    traffic_noise_problem: 0,
    garbage_noise_problem: 0,

    student: 0,
    env_problem_total: 0,
    studentsDisability: 0,
    studentsDisabvantaged: 0,

    disadvantagedCharts: [{
      name: '',
      colorByPoint: true,
      data: [
        { name: 'เล็ก', y: 0, color: "#006FD0" },
        { name: 'ใหญ่พิเศษ', y: 0, color: "#7EADEF" },
        { name: 'ใหญ่', y: 0, color: '#B7F2FF' },
        { name: 'กลาง', y: 0, color: '#8AC8FF' }
      ]
    }],
    internet: [{
      name: 'ข้อมูลจำนวนโรงเรียนที่ใช้งานอินเตอร์เน็ตเเต่ละประเภท',
      color: '#fff',
      data: [
        { name: '2G_3G_4G', y: 0, color: "#007233" },
        { name: 'fiber_optic', y: 0, color: "#009A45" },
        { name: 'adsl', y: 0, color: '#00B651' },
        { name: 'leased_line', y: 0, color: '#5DBB87' },
        { name: 'other', y: 0, color: '#68D99A' },
        { name: 'satellite', y: 0, color: '#9EFFCA' },
        { name: 'ไม่มีอินเตอร์เน็ต', y: 0, color: "#007233" },
        { name: 'ไม่ระบุ', y: 0, color: "#009A45" },
        { name: 'mpls', y: 0, color: '#00B651' },
        { name: 'ip_star', y: 0, color: '#5DBB87' },
        { name: 'dsl_vpn', y: 0, color: '#68D99A' },
        { name: 'ip_vpn', y: 0, color: '#9EFFCA' }

      ],
    }],
    water: [{
      name: 'ข้อมูลจำนวนโรงเรียนที่ใช้แหล่งน้ำแต่ละประเภท',
      color: '#fff',
      data: [
        { name: 'ถังน้ำไฟเบอร์กลาส', y: 0, color: "#1D56B0" },
        { name: 'ถังเก็บน้ำซีเมนต์', y: 0, color: "#4381E3" },
        { name: 'ประปานครหลวง', y: 0, color: '#2597CC' },
        { name: 'ประปาเทศบาล', y: 0, color: '#48BCF1' },
        { name: 'ประปาจากหน่วยงานอื่น', y: 0, color: '#5FB6DE' },
        { name: 'ประปาส่วนภูมิภาค', y: 0, color: '#5FBFEB' },
        { name: 'ประปาโรงเรียน', y: 0, color: '#7ACFF6' },
        { name: 'ประปาหมู่บ้าน', y: 0, color: '#8ED1F0' },
        { name: 'สระเก็บน้ำ', y: 0, color: '#97CBE2' },
        { name: 'แม่น้ำ/ลำธาร', y: 0, color: '#9CD1FF' },
        { name: 'ไม่ระบุ', y: 0, color: '#B5E8FF' },
      ],
    }],
    classroomCharts: [{
      name: 'ข้อมูลจำนวนนักเรียนแบ่งตามระดับชั้น',
      color: '#fff',
      data: [
        { name: 'อ.1', y: 0, color: "#5DBB87" },
        { name: 'อ.2', y: 0, color: "#5DBB87" },
        { name: 'อ.3', y: 0, color: '#5DBB87' },
        { name: 'ป.1', y: 0, color: '#5DBB87' },
        { name: 'ป.2', y: 0, color: '#5DBB87' },
        { name: 'ป.3', y: 0, color: '#5DBB87' },
        { name: 'ป.4', y: 0, color: '#5DBB87' },
        { name: 'ป.5', y: 0, color: '#5DBB87' },
        { name: 'ป.6', y: 0, color: '#5DBB87' },
        { name: 'ม.1', y: 0, color: '#5DBB87' },
        { name: 'ม.2', y: 0, color: '#5DBB87' },
        { name: 'ม.3', y: 0, color: '#5DBB87' },
        { name: 'ม.4', y: 0, color: '#5DBB87' },
        { name: 'ม.5', y: 0, color: '#5DBB87' },
        { name: 'ม.6', y: 0, color: '#5DBB87' },
      ],
    }],
  }

  data
    .filter(_filterData)
    .filter(x => x.year === filter.year)
    .forEach(x => {
      result.total += (x[`school_sizeS`] + x[`school_sizeL`] + x[`school_sizeX`] + x[`school_sizeXL`])
      result.sizeS += x[`school_sizeS`]
      result.sizeL += x[`school_sizeL`]
      result.sizeX += x[`school_sizeX`]
      result.sizeXL += x[`school_sizeXL`]

      result.teacher_total += x[`teacher_total`]
      result.teacher_total_f += x[`teacher_total_f`]
      result.teacher_total_m += x[`teacher_total_m`]

      result.desktop_computer += (x[`desktop_computer_for_manage_can_be_used`] + x[`desktop_computer_for_learning_can_be_used`])
      result.notebook += (x[`notebook_for_manage_can_be_used`] + x[`notebook_for_learning_can_be_used`])

      result.desktop_computer_for_manage_can_be_used += x[`desktop_computer_for_manage_can_be_used`]
      result.desktop_computer_for_learning_can_be_used += x[`desktop_computer_for_learning_can_be_used`]
      result.notebook_for_manage_can_be_used += x[`notebook_for_manage_can_be_used`]
      result.notebook_for_learning_can_be_used += x[`notebook_for_learning_can_be_used`]

      result.student_total += x[`student_total`]

      result.school_has_electricity += x[`school_has_electricity`]
      result.school_not_electricity += x[`school_not_electricity`]
      result.school_has_electricity_service_area += x[`school_has_electricity_service_area`]
      result.school_not_electricity_service_area += x[`school_not_electricity_service_area`]

      result.disadvantagedCharts[0].data[0].y += x[`school_sizeL`]
      result.disadvantagedCharts[0].data[1].y += x[`school_sizeS`]
      result.disadvantagedCharts[0].data[2].y += x[`school_sizeX`]
      result.disadvantagedCharts[0].data[3].y += x[`school_sizeXL`]


      result.classroomCharts[0].data[0].y += x[`k1`]
      result.classroomCharts[0].data[1].y += x[`k2`]
      result.classroomCharts[0].data[2].y += x[`k3`]
      result.classroomCharts[0].data[3].y += x[`p1`]
      result.classroomCharts[0].data[4].y += x[`p2`]
      result.classroomCharts[0].data[5].y += x[`p3`]
      result.classroomCharts[0].data[6].y += x[`p4`]
      result.classroomCharts[0].data[7].y += x[`p5`]
      result.classroomCharts[0].data[8].y += x[`p6`]
      result.classroomCharts[0].data[9].y += x[`m1`]
      result.classroomCharts[0].data[10].y += x[`m2`]
      result.classroomCharts[0].data[11].y += x[`m3`]
      result.classroomCharts[0].data[12].y += x[`m4`]
      result.classroomCharts[0].data[13].y += x[`m5`]
      result.classroomCharts[0].data[14].y += x[`m6`]

      result.internet[0].data[0].y += x[`internet_2G_3G_4G`]
      result.internet[0].data[1].y += x[`internet_fiber_optic`]
      result.internet[0].data[2].y += x[`internet_adsl`]
      result.internet[0].data[3].y += x[`internet_leased_line`]
      result.internet[0].data[4].y += x[`internet_other`]
      result.internet[0].data[5].y += x[`internet_satellite`]
      result.internet[0].data[6].y += x[`internet_no`]
      result.internet[0].data[7].y += x[`internet_unspecified`]
      result.internet[0].data[8].y += x[`internet_mpls`]
      result.internet[0].data[9].y += x[`internet_ip_star`]
      result.internet[0].data[10].y += x[`internet_ip_vpn`]

      result.water[0].data[0].y += x[`water_fiberglass`]
      result.water[0].data[1].y += x[`water_cement`]
      result.water[0].data[2].y += x[`water_plumbing_metropolitan_agency`]
      result.water[0].data[3].y += x[`water_plumbing_municipal`]
      result.water[0].data[4].y += x[`water_plumbing_other_agency`]
      result.water[0].data[5].y += x[`water_plumbing_provincial_agency`]
      result.water[0].data[6].y += x[`water_plumbing_school`]
      result.water[0].data[7].y += x[`water_plumbing_village`]
      result.water[0].data[8].y += x[`water_shallow_pond`]
      result.water[0].data[9].y += x[`water_river`]
      result.water[0].data[10].y += x[`water_unspecified`]

      result.agricultural_chemistry_air_problem += x[`agricultural_chemistry_air_problem`]
      result.factory_air_problem += x[`factory_air_problem`]
      result.traffic_air_problem += x[`traffic_air_problem`]
      result.garbage_air_problem += x[`garbage_air_problem`]

      result.agricultural_chemistry_water_problem += x[`agricultural_chemistry_water_problem`]
      result.factory_water_problem += x[`factory_water_problem`]
      result.traffic_water_problem += x[`traffic_water_problem`]
      result.garbage_water_problem += x[`garbage_water_problem`]

      result.agricultural_chemistry_noise_problem += x[`agricultural_chemistry_noise_problem`]
      result.factory_noise_problem += x[`factory_noise_problem`]
      result.traffic_noise_problem += x[`traffic_noise_problem`]
      result.garbage_noise_problem += x[`garbage_noise_problem`]

      result.env_problem_total += (
        x[`agricultural_chemistry_air_problem`] +
        x[`factory_air_problem`] +
        x[`traffic_air_problem`] +
        x[`garbage_air_problem`] +

        x[`agricultural_chemistry_water_problem`] +
        x[`factory_water_problem`] +
        x[`traffic_water_problem`] +
        x[`garbage_water_problem`] +

        x[`agricultural_chemistry_noise_problem`] +
        x[`factory_noise_problem`] +
        x[`traffic_noise_problem`] +
        x[`garbage_noise_problem`]
      )

    })

  result.water[0].data = result.water[0].data.sort((a, b) => b.y - a.y)
  result.water[0].data = result.water[0].data.map((x, i) => ({ ...x, color: `hsl(208, 100%, ${70 * (i / result.water[0].data.length) + 30}%)` }))

  result.internet[0].data = result.internet[0].data.sort((a, b) => b.y - a.y)
  result.internet[0].data = result.internet[0].data.map((x, i) => ({ ...x, color: `hsl(147, 100%, ${70 * (i / result.internet[0].data.length) + 30}%)` }))
  return (result)

}

const mapDataTable = (typessssd, data, year = 'all') => {
  if (typessssd === 'table1') {
    return (data.map(_data => {
      return ({
        sizeS: _data.school_sizeS,
        sizeL: _data.school_sizeL,
        sizeX: _data.school_sizeX,
        sizeXL: _data.school_sizeXL,
        school_all: _data.school_sizeS + _data.school_sizeL + _data.school_sizeX + _data.school_sizeXL,
        province_name: _data.province_name,
        city_name: _data.city_name,
        tumbon_name: _data.tumbon_name,
        sungkud: _data.sungkud,
        area: _data.area,
      })
    }))
  } else if (typessssd === 'table2') {
    return (data.map(_data => {
      return ({
        stdent_all: (
          _data.k1 +
          _data.k2 +
          _data.k3 +
          _data.p1 +
          _data.p2 +
          _data.p3 +
          _data.p4 +
          _data.p5 +
          _data.p6 +
          _data.m1 +
          _data.m2 +
          _data.m3 +
          _data.m4 +
          _data.m5 +
          _data.m6
        ),
        stdent_class_k: (
          _data.k1 +
          _data.k2 +
          _data.k3
        ),
        stdent_class_p: (
          _data.p1 +
          _data.p2 +
          _data.p3 +
          _data.p4 +
          _data.p5 +
          _data.p6
        ),
        stdent_class_mt: (
          _data.m1 +
          _data.m2 +
          _data.m3
        ),
        stdent_class_hi: (
          _data.m4 +
          _data.m5 +
          _data.m6
        ),
        province_name: _data.province_name,
        city_name: _data.city_name,
        tumbon_name: _data.tumbon_name,
        sungkud: _data.sungkud,
        area: _data.area,
      })
    }))
  } else if (typessssd === 'table3') {
    return (data.map(_data => {

      return ({
        internet_2G_3G_4G: _data[`internet_2G_3G_4G`],
        internet_adsl: _data[`internet_adsl`],
        internet_fiber_optic: _data[`internet_fiber_optic`],
        internet_leased_line: _data[`internet_leased_line`],
        internet_other: _data[`internet_other`],
        internet_satellite: _data[`internet_satellite`],
        internet_no: _data[`internet_no`],

        province_name: _data.province_name,
        city_name: _data.city_name,
        tumbon_name: _data.tumbon_name,
        sungkud: _data.sungkud,
        area: _data.area,
      })
    }))
  } else if (typessssd === 'table4') {
    return (data.map(_data => {

      return ({
        water_fiberglass: _data[`water_fiberglass`],
        water_cement: _data[`water_cement`],
        water_plumbing_metropolitan_agency: _data[`water_plumbing_metropolitan_agency`],
        water_plumbing_municipal: _data[`water_plumbing_municipal`],
        water_plumbing_other_agency: _data[`water_plumbing_other_agency`],
        water_plumbing_provincial_agency: _data[`water_plumbing_provincial_agency`],
        water_plumbing_school: _data[`water_plumbing_school`],
        water_plumbing_village: _data[`water_plumbing_village`],
        water_shallow_pond: _data[`water_shallow_pond`],
        water_river: _data[`water_river`],
        water_unspecified: _data[`water_unspecified`],

        province_name: _data.province_name,
        city_name: _data.city_name,
        tumbon_name: _data.tumbon_name,
        sungkud: _data.sungkud,
        area: _data.area,
      })
    }))
  } else if (typessssd === 'table6') {
    return data
      .map(_data => {
        let allJonMen = (
          _data[`studentJonMen${year}`] +
          _data[`studentJonWomen${year}`] +
          _data[`studentExJonMen${year}`] +
          _data[`studentExJonWomen${year}`]
        )

        return ({
          studentTotal: _data[`studentMen${year}`] + _data[`studentWomen${year}`],
          studentJondmc: _data[`studentJonMen${year}`] + _data[`studentJonWomen${year}`],
          studentJon: _data[`studentJonMen${year}`] + _data[`studentJonWomen${year}`],
          studentExJon: _data[`studentExJonMen${year}`] + _data[`studentExJonWomen${year}`],
          studentJonAndExJon: allJonMen,
          province_name: _data.province_name,
          city_name: _data.city_name,
          tumbon_name: _data.tumbon_name,
          sungkud: _data.sungkud,
          area: _data.area,
        })
      })
  }
}

export default function StuentJon(props) {
  let [dataSummaryAllyear, setDataSummaryAllyear] = React.useState([])
  const [filter, setFilter] = React.useState({
    // type: props.pageContext.type,
    // view: props.pageContext.view,
    // year: props.pageContext.year,
    type: 'all',
    view: 'province',
    year: "2566",
    data: [],
    province_name: null,
    city_name: null,
    tumbon_name: null,
    sungkud: 'สพฐ.',
    area: null,
  })

  const [school, setSchool] = React.useState({
    total: 0,
    sizeS: 0,
    sizeL: 0,
    sizeX: 0,
    sizeXL: 0,
    desktop_computer: 0,
    notebook: 0,
    desktop_computer_for_manage_can_be_used: 0,
    desktop_computer_for_learning_can_be_used: 0,
    notebook_for_manage_can_be_used: 0,
    notebook_for_learning_can_be_used: 0,
    teacher_total: 0,
    teacher_total_f: 0,
    teacher_total_m: 0,
  });

  React.useEffect(() => {
    setSchool(mapData(dataSummaryAllyear, filter))
  }, [filter, dataSummaryAllyear]);

  React.useEffect(async () => {
    let res = await api.getInitData('target-resources-country')
    setDataSummaryAllyear(res.data)
  }, [])

  React.useEffect(() => {
    setSchool(mapData(dataSummaryAllyear, filter))
  }, [filter, dataSummaryAllyear]);

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isModalQinfoVisible, setIsModalQinfoVisible] = React.useState(false);
  const [columns, setColumns] = React.useState([]);
  const [dataTable, setDataTable] = React.useState([]);
  const [title, setTitle] = React.useState('');

  const [qinfo, setQinfo] = React.useState({
    description: '',
    refs: []
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  return (
    <Layout Role={['eef']}>
      {
        filter.view === 'sungkud' ?
          <ModalTabelBySungkud
            title={title}
            isModalVisible={isModalVisible}
            dataSource={dataTable}
            columns={columns}
            handleOk={handleOk}
            handleCancel={handleCancel}
            rowSpan={2}
          />
          :
          <ModalTabelByProvicce
            title={title}
            isModalVisible={isModalVisible}
            dataSource={dataTable}
            columns={columns}
            handleOk={handleOk}
            handleCancel={handleCancel}
            rowSpan={2}
          />
      }
      < ModalQinfo
        refs={qinfo.refs}
        description={qinfo.description}
        isModalVisible={isModalQinfoVisible}
        handleCancel={() => {
          setIsModalQinfoVisible(false)
          setQinfo({
            description: '',
            refs: []
          });
        }}
        handleOk={() => {
          setIsModalQinfoVisible(false)
          setQinfo({
            description: '',
            refs: []
          });
        }}
      />
      < div style={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        backgroundImage: 'url(' + ImageBG + ')',
        backgroundSize: 'contain'
      }}>
        <div style={{
          width: '1200px',
          backgroundColor: 'rgba(255, 255, 255, 0.6)',
          paddingTop: 60,
          marginBottom: 200
        }}>
          {/* 2px 8px 2px 8px */}
          <Banner
            title={'ทรัพยากรโรงเรียน'}
            description={`ทรัพยากรทางการศึกษา หมายถึง สิ่งต่าง ๆ ที่ใช้ในการบริหารจัดการศึกษาให้บรรลุตามเป้าหมายของสถานศึกษา โดยมีองค์ประกอบพื้นฐาน ได้แก่ คน เงิน วัสดุ อุปกรณ์ และอาคารสถานที่ภูมิปัญญาในชุมชน ที่นำมาใช้ในการจัดการศึกษาอย่างมีประสิทธิภาพ`}
            extra={
              <div style={{ fontSize: '0.9rem', marginTop: '20px', fontFamily: 'Bai Jamjuree' }}>
                {`ข้อมูลจาก "สำนักงานคณะกรรมการการศึกษาขั้นพื้นฐาน"`}
              </div>
            }
            image={ImageBannerd} />

          {/* <div className="shadow-card w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-sm px-2 py-4"> */}
          <div className="shadow-card w-full bg-[#FFFFFF] rounded-xl px-2 py-4">
            <Filter
              optionYear={[
                { value: '2566', text: '2566' },
                { value: '2565', text: '2565' },
                { value: '2564', text: '2564' },
                { value: '2563', text: '2563' },
                // { value: '2562', text: '2562' },
                // { value: '2561', text: '2561' },
              ]}
              optionSungkud={[
                // { value: null, text: 'ทั้งหมด' },
                { value: 'สพฐ.', text: 'สำนักงานคณะกรรมการการศึกษาขั้นพื้นฐาน (สพฐ.)' },
                // { value: 'อปท.', text: 'กรมส่งเสริมการปกครองท้องถิ่น (อปท.)' },
                // { value: 'ตชด.', text: 'กองบัญชาการตำรวจตระเวนชายแดน (ตชด.)' },
                // { value: 'พศ.', text: 'สำนักงานพระพุทธศาสนาแห่งชาติ (พศ.)' },
                // { value: 'สช.', text: 'สำนักงานคณะกรรมการส่งเสริมการศึกษาเอกชน (สช.)' },
              ]}
              data={filter}
              onChange={({
                year,
                province,
                district,
                subDistrict,
                type,
                area,
                sungkud,
                view
              }) => {
                if (filter.view !== view) {
                  if (view === "sungkud") {
                    navigate(`/target-resources/sungkud`)
                  } else {
                    navigate(`/target-resources/country`)
                  }
                } else {
                  setFilter(
                    {
                      ...filter,
                      year: year,
                      tumbon_name: subDistrict,
                      city_name: district,
                      province_name: province,
                      area,
                      // sungkud,
                      type: type,
                      view: view,
                    })
                }
              }}
            />
          </div>

          <div className="grid grid-cols-12 gap-x-4 gap-y-4 w-full mt-8 px-4 md:px-0">
            <div className="col-span-12 md:col-span-4 h-[220px]">
              {/* <DrilldownModal title={``} columns={[{title:'จังหวัด',key : 'province_name',align : 'center'}]} data={data.drilldown.controlProvince}> */}
                <div className="shadow-card w-full bg-[#FFFFFF] rounded-xl px-2 py-4 h-[200px]">
                  <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                    <div className="text-2xl">ข้อมูลโรงเรียน</div>
                    <div style={{ width: '72px', justifyContent: 'space-around', flexDirection: 'row', display: 'flex' }} >
                      <div style={{ width: '24px', justifyContent: 'space-around' }} >
                      </div>
                      <div style={{ width: '24px', justifyContent: 'space-around' }} >
                        <InfoCircleOutlined style={{ fontSize: '24px', color: '#374151' }}
                          onClick={() => {
                            setQinfo({
                              description: ` โรงเรียนแต่ละโรงเรียนสามารถถูกจำแนกออกตามขนาด ซึ่งการแบ่งตามขนาดนั้นขึ้นอยู่กับจำนวนนักเรียนในโรงเรียนนั้น ๆ ด้วย เช่น โรงเรียนขนาดใหญ่พิเศษ โรงเรียนขนาดใหญ่ โรงเรียนขนาดกลาง และโรงเรียนขนาดเล็ก โดยจำนวนนักเรียนในโรงเรียนที่จะเป็นเกณฑ์การแบ่งขนาดโรงเรียน`,
                              refs: ['ข้อมูลโรงเรียน DMC ปีการศึกษา 2563 - 2566']
                            });
                            setIsModalQinfoVisible(true)
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="text-5xl text-[#0D47A1] text-center mt-4">  {school.total.toLocaleString('en-US')}</div>
                  <div className="text-2xl text-center mt-1">  จำนวนโรงเรียนทั้งหมด</div>
                </div>
              {/* </DrilldownModal> */}
            </div>

            <div className="col-span-12 md:col-span-4 h-[220px]">
              <div className="shadow-card w-full bg-[#FFFFFF] rounded-xl p-2 mb-4 h-[90px]">
                <div className="text-xl mb-1">  โรงเรียนขนาดเล็ก</div>
                <div className="text-4xl text-[#1E88E5] text-right"> {school.sizeS.toLocaleString('en-US')} <span className="text-lg text-black">โรงเรียน </span></div>
              </div>

              <div className="shadow-card w-full bg-[#FFFFFF] rounded-xl p-2 mt-4 h-[90px]">
                <div className="text-xl mb-1">  โรงเรียนขนาดใหญ่</div>
                <div className="text-4xl text-[#1E88E5] text-right"> {school.sizeX.toLocaleString('en-US')} <span className="text-lg text-black">โรงเรียน </span></div>
              </div>
            </div>

            <div className="col-span-12 md:col-span-4 h-[220px]">
              <div className="shadow-card w-full bg-[#FFFFFF] rounded-xl p-2 mb-4 h-[90px]">
                <div className="text-xl mb-1">  โรงเรียนขนาดกลาง</div>
                <div className="text-4xl text-[#1E88E5] text-right"> {school.sizeL.toLocaleString('en-US')} <span className="text-lg text-black">โรงเรียน </span></div>
              </div>

              <div className="shadow-card w-full bg-[#FFFFFF] rounded-xl p-2 mt-4 h-[90px]">
                <div className="text-xl mb-1">  โรงเรียนขนาดใหญ่พิเศษ</div>
                <div className="text-4xl text-[#1E88E5] text-right"> {school.sizeXL.toLocaleString('en-US')} <span className="text-lg text-black">โรงเรียน </span></div>
              </div>
            </div>

            <div className="col-span-12 md:col-span-6">
              <div className="shadow-card w-full bg-[#FFFFFF] rounded-xl px-2 py-4 h-[380px]" >
                <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-evenly' }}>
                  <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{}}>
                      <div className="text-xl">ข้อมูลโรงเรียน</div>
                      <div className="text-md">ปีการศึกษา {filter.year}</div>
                    </div>
                    <div style={{ width: '72px', justifyContent: 'space-around', flexDirection: 'row', display: 'flex' }} >
                      <div style={{ width: '24px', justifyContent: 'space-around' }} >
                        <TableOutlined style={{ fontSize: '24px', color: '#374151' }} onClick={() => {
                          showModal()
                          setColumns(confixColumnsTable.target_resources_school_size)
                          setDataTable(mapDataTable('table1', dataSummaryAllyear, filter.year))
                          setTitle('จำนวนนักเรียนทั้งหมดในประเทศไทย ปีการศึกษา ' + filter.year)
                        }} />
                      </div>
                      <div style={{ width: '24px', justifyContent: 'space-around' }} >
                        <InfoCircleOutlined style={{ fontSize: '24px', color: '#374151' }}
                          onClick={() => {
                            setQinfo({
                              description: ` โรงเรียนแต่ละโรงเรียนสามารถถูกจำแนกออกตามขนาด ซึ่งการแบ่งตามขนาดนั้นขึ้นอยู่กับจำนวนนักเรียนในโรงเรียนนั้น ๆ ด้วย เช่น โรงเรียนขนาดใหญ่พิเศษ โรงเรียนขนาดใหญ่ โรงเรียนขนาดกลาง และโรงเรียนขนาดเล็ก โดยจำนวนนักเรียนในโรงเรียนที่จะเป็นเกณฑ์การแบ่งขนาดโรงเรียน`,
                              refs: ['ข้อมูลโรงเรียน DMC ปีการศึกษา 2563 - 2566']
                            });
                            setIsModalQinfoVisible(true)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div >
                  <Chart
                    options={{
                      title: null,
                      type: 'pie',
                    }}
                    click={({ e }) => {
                      showModal()
                      setColumns(confixColumnsTable.target_resources_school_size)
                      setDataTable(mapDataTable('table1', dataSummaryAllyear, filter.year))
                      setTitle('จำนวนนักเรียนทั้งหมดในประเทศไทย ปีการศึกษา ' + filter.year)
                    }}
                    containerProps={{ style: { height: "250px" } }}
                    style={{ width: '150px', height: '260px' }}
                    data={school.disadvantagedCharts}
                  />
                </div>
              </div>
            </div>

            <div className="col-span-12 md:col-span-6">
              <div className="shadow-card w-full bg-[#FFFFFF] rounded-xl px-2 py-4 h-[380px]" >
                <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-evenly' }}>
                  <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{}}>
                      <div className="text-xl">ข้อมูลโรงเรียนแบ่งระดับชั้น</div>
                      <div className="text-md">ปีการศึกษา {filter.year}</div>
                    </div>
                    <div style={{ width: '72px', justifyContent: 'space-around', flexDirection: 'row', display: 'flex' }} >
                      <div style={{ width: '24px', justifyContent: 'space-around' }} >
                        <TableOutlined style={{ fontSize: '24px', color: '#374151' }} onClick={() => {
                          showModal()
                          setColumns(confixColumnsTable.target_resources_school_class)
                          setDataTable(mapDataTable('table2', dataSummaryAllyear, filter.year))
                          setTitle('ข้อมูลจำนวนนักเรียนแบ่งตามช่วงชั้น ปีการศึกษา ' + filter.year)
                        }} />
                      </div>
                      <div style={{ width: '24px', justifyContent: 'space-around' }} >
                        <InfoCircleOutlined style={{ fontSize: '24px', color: '#374151' }}
                          onClick={() => {
                            setQinfo({
                              description: ` โรงเรียนแต่ละโรงเรียนสามารถถูกจำแนกออกตามขนาด ซึ่งการแบ่งตามขนาดนั้นขึ้นอยู่กับจำนวนนักเรียนในโรงเรียนนั้น ๆ ด้วย เช่น โรงเรียนขนาดใหญ่พิเศษ โรงเรียนขนาดใหญ่ โรงเรียนขนาดกลาง และโรงเรียนขนาดเล็ก โดยจำนวนนักเรียนในโรงเรียนที่จะเป็นเกณฑ์การแบ่งขนาดโรงเรียน`,
                              refs: ['ข้อมูลโรงเรียน DMC ปีการศึกษา 2563 - 2566']
                            });
                            setIsModalQinfoVisible(true)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <Chart
                    options={{
                      legend_enabled: false,
                      title: '',
                      type: 'bar',
                      categories: [
                        'อ.1',
                        'อ.2',
                        'อ.3',
                        'ป.1',
                        'ป.2',
                        'ป.3',
                        'ป.4',
                        'ป.5',
                        'ป.6',
                        'ม.1',
                        'ม.2',
                        'ม.3',
                        'ม.4',
                        'ม.5',
                        'ม.6',
                      ]
                    }}
                    containerProps={{ style: { height: "300px" } }}
                    style={{ height: '310px' }}
                    click={({ e, category, name }) => {
                      showModal()
                      setColumns(confixColumnsTable.target_resources_school_class)
                      setDataTable(mapDataTable('table2', dataSummaryAllyear, filter.year))
                      setTitle('ข้อมูลจำนวนนักเรียนแบ่งตามช่วงชั้น ปีการศึกษา ' + filter.year)
                    }}
                    data={school.classroomCharts}
                  />
                </div>
              </div>
            </div>


            <div className="col-span-12 md:col-span-6 h-min md:h-[220px]">
              <div className="shadow-card w-full bg-[#FFFFFF] rounded-xl px-2 py-4 h-[200px]">
                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                  <div className="text-2xl">ข้อมูลบุคลากร</div>
                  <div style={{ width: '72px', justifyContent: 'space-around', flexDirection: 'row', display: 'flex' }} >
                    <div style={{ width: '24px', justifyContent: 'space-around' }} >
                    </div>
                    <div style={{ width: '24px', justifyContent: 'space-around' }} >
                      <InfoCircleOutlined style={{ fontSize: '24px', color: '#374151' }}
                        onClick={() => {
                          setQinfo({
                            description: ` โรงเรียนแต่ละโรงเรียนสามารถถูกจำแนกออกตามขนาด ซึ่งการแบ่งตามขนาดนั้นขึ้นอยู่กับจำนวนนักเรียนในโรงเรียนนั้น ๆ ด้วย เช่น โรงเรียนขนาดใหญ่พิเศษ โรงเรียนขนาดใหญ่ โรงเรียนขนาดกลาง และโรงเรียนขนาดเล็ก โดยจำนวนนักเรียนในโรงเรียนที่จะเป็นเกณฑ์การแบ่งขนาดโรงเรียน`,
                            refs: ['ข้อมูลโรงเรียน DMC ปีการศึกษา 2563 - 2566']
                          });
                          setIsModalQinfoVisible(true)
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="text-5xl text-[#0D47A1] text-center mt-4">  {school.teacher_total.toLocaleString('en-US')}</div>
                <div className="text-2xl text-center mt-1">  จำนวนครูและบุคลากรทั้งหมด</div>
              </div>
            </div>


            <div className="col-span-12 md:col-span-6 h-min md:h-[220px]">
              <div className="shadow-card w-full bg-[#FFFFFF] rounded-xl px-2 py-4 h-[200px]">
                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                  <div className="text-2xl">อัตราส่วนครูต่อนักเรียน</div>
                  <div style={{ width: '72px', justifyContent: 'space-around', flexDirection: 'row', display: 'flex' }} >
                    <div style={{ width: '24px', justifyContent: 'space-around' }} >
                    </div>
                  </div>
                </div>
                <div className="text-5xl text-[#0D47A1] text-center mt-4">  {Number.isFinite(school.student_total / school.teacher_total) ? `1:${Math.ceil((school.student_total / school.teacher_total).toFixed(0))}` : '-'}</div>
              </div>
            </div>
            <div className="col-span-12 md:col-span-3">
              <div className="shadow-card w-full bg-[#FFFFFF] rounded-xl" >
                <ProgressView
                  percent={(school.school_has_electricity / school.total)}
                  amount={school.school_has_electricity} title="มีไฟฟ้าใช้"
                />
              </div>
            </div>
            <div className="col-span-12 md:col-span-3">
              <div className="shadow-card w-full bg-[#FFFFFF] rounded-xl" >
                <ProgressView
                  percent={school.school_not_electricity / school.total}
                  amount={school.school_not_electricity} title="ไม่มีไฟฟ้าใช้"
                />
              </div>
            </div>

            <div className="col-span-12 md:col-span-3">
              <div className="shadow-card w-full bg-[#FFFFFF] rounded-xl" >
                <ProgressView
                  percent={school.school_has_electricity_service_area / school.total}
                  amount={school.school_has_electricity_service_area} title="อยู่ในเขตบริการไฟฟ้า"
                />
              </div>
            </div>

            <div className="col-span-12 md:col-span-3">
              <div className="shadow-card w-full bg-[#FFFFFF] rounded-xl" >
                <ProgressView
                  percent={school.school_not_electricity_service_area / school.total}
                  amount={school.school_not_electricity_service_area} title="อยู่นอกเขตบริการไฟฟ้า"
                />
              </div>
            </div>

            <div className="col-span-12 md:col-span-3">
              <div className="shadow-card w-full bg-[#FFFFFF] rounded-xl px-2 py-4 h-[240px]" >
                <div className="text-lg mt-2 text-center">จำนวนคอมพิวเตอร์ทั้งหมด</div>
                <div className="text-md text-center">  จำนวน  <span className='text-2xl text-[#0D86CA]'> {(school.desktop_computer + school.notebook).toLocaleString('en-US')} </span>  เครื่อง</div>
                <div className="text-lg mt-2 text-center">คอมพิวเตอร์ตั้งโต๊ะ</div>
                <div className="text-md text-center">  จำนวน  <span className='text-2xl text-[#0D86CA]'> {(school.desktop_computer).toLocaleString('en-US')} </span>  เครื่อง</div>
                <div className="text-lg mt-2 text-center">Notebook</div>
                <div className="text-md text-center">  จำนวน  <span className='text-2xl text-[#0D86CA]'> {(school.notebook).toLocaleString('en-US')} </span>  เครื่อง</div>
              </div>
            </div>

            <div className="col-span-12 md:col-span-9">
              <div className="shadow-card w-full bg-[#FFFFFF] rounded-xl px-2 py-4 h-[240px]" >
                <div className='flex flex-row mt-2'>
                  <div className="w-[200px]">
                    <p className='m-0 text-2xl'>คอมพิวเตอร์ตั้งโต๊ะ</p>
                  </div>
                  <div className="flex flex-1">
                  </div>
                  <div className="w-[70px]">
                    <p className='m-0 mt-1 text-right'>เครื่อง</p>
                  </div>
                  <div className="w-[70px]">
                    <p className='m-0 mt-1 text-right'>ร้อยละ</p>
                  </div>
                </div>

                <div className='flex flex-row h-4 m-1'>
                  <div className="w-[120px]">
                    <h1 className='m-0'>งานธุรการ</h1>
                  </div>
                  <div className="flex flex-1 px-1">
                    <div className="w-full bg-gray-200 rounded-full h-4">
                      <BarView color={"#0D86CA"} percent={(school?.desktop_computer_for_manage_can_be_used / (school.desktop_computer)) * 100} />
                    </div>
                  </div>
                  <div className="w-[70px]">
                    <h1 className='m-0 text-right'>{school?.desktop_computer_for_manage_can_be_used.toLocaleString('en-US')}</h1>
                  </div>
                  <div className="w-[70px]">
                    <h1 className='m-0 text-right'>{((school?.desktop_computer_for_manage_can_be_used / school.desktop_computer) * 100).toFixed(2)}</h1>
                  </div>
                </div>
                <div className='flex flex-row h-4 m-1'>
                  <div className="w-[120px]">
                    <h1 className='m-0'>การเรียนการสอน</h1>
                  </div>
                  <div className="flex flex-1 px-1">
                    <div className="w-full bg-gray-200 rounded-full h-4">
                      <BarView color={"#0DCA86"} percent={(school?.desktop_computer_for_learning_can_be_used / (school.desktop_computer)) * 100} />
                    </div>
                  </div>
                  <div className="w-[70px]">
                    <h1 className='m-0 text-right'>{school?.desktop_computer_for_learning_can_be_used.toLocaleString('en-US')}</h1>
                  </div>
                  <div className="w-[70px]">
                    <h1 className='m-0 text-right'>{((school?.desktop_computer_for_learning_can_be_used / school.desktop_computer) * 100).toFixed(2)}</h1>
                  </div>
                </div>


                <div className='flex flex-row mt-8'>
                  <div className="w-[200px]">
                    <p className='m-0 text-2xl'>Notebook</p>
                  </div>
                  <div className="flex flex-1">
                  </div>
                  <div className="w-[70px]">
                    <p className='m-0 mt-1 text-right'>เครื่อง</p>
                  </div>
                  <div className="w-[70px]">
                    <p className='m-0 mt-1 text-right'>ร้อยละ</p>
                  </div>
                </div>

                <div className='flex flex-row h-4 m-1'>
                  <div className="w-[120px]">
                    <h1 className='m-0'>งานธุรการ</h1>
                  </div>
                  <div className="flex flex-1 px-1">
                    <div className="w-full bg-gray-200 rounded-full h-4">
                      <BarView color={"#0D86CA"} percent={(school?.notebook_for_manage_can_be_used / (school.notebook)) * 100} />
                    </div>
                  </div>
                  <div className="w-[70px]">
                    <h1 className='m-0 text-right'>{school?.notebook_for_manage_can_be_used.toLocaleString('en-US')}</h1>
                  </div>
                  <div className="w-[70px]">
                    <h1 className='m-0 text-right'>{((school?.notebook_for_manage_can_be_used / school.notebook) * 100).toFixed(2)}</h1>
                  </div>
                </div>
                <div className='flex flex-row h-4 m-1'>
                  <div className="w-[120px]">
                    <h1 className='m-0'>การเรียนการสอน</h1>
                  </div>
                  <div className="flex flex-1 px-1">
                    <div className="w-full bg-gray-200 rounded-full h-4">
                      <BarView color={"#0DCA86"} percent={(school?.notebook_for_learning_can_be_used / (school.notebook)) * 100} />
                    </div>
                  </div>
                  <div className="w-[70px]">
                    <h1 className='m-0 text-right'>{school?.notebook_for_learning_can_be_used.toLocaleString('en-US')}</h1>
                  </div>
                  <div className="w-[70px]">
                    <h1 className='m-0 text-right'>{((school?.notebook_for_learning_can_be_used / school.notebook) * 100).toFixed(2)}</h1>
                  </div>
                </div>

              </div>
            </div>

            <div className="col-span-12">
              <div className="shadow-card w-full bg-[#FFFFFF] rounded-xl px-2 py-4" >
                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                  <div className='flex flex-col'>
                    <div className="text-2xl">ข้อมูลอินเตอร์เน็ต</div>
                    <div className="text-md">ข้อมูลจำนวนโรงเรียนที่ใช้งานอินเตอร์เน็ตเเต่ละประเภท</div>
                  </div>
                  <div style={{ width: '72px', justifyContent: 'space-around', flexDirection: 'row', display: 'flex' }} >
                    <div style={{ width: '24px', justifyContent: 'space-around' }} >
                      <TableOutlined style={{ fontSize: '24px', color: '#374151' }} onClick={() => {
                        showModal()
                        setColumns(confixColumnsTable.target_resources_internet)
                        setDataTable(mapDataTable('table3', dataSummaryAllyear, filter.year))
                        setTitle('ข้อมูลจำนวนโรงเรียนที่ใช้งานอินเตอร์เน็ตแต่ละประเภท ปีการศึกษา ' + filter.year)
                      }} />
                    </div>
                    <div style={{ width: '24px', justifyContent: 'space-around' }} >
                      <InfoCircleOutlined style={{ fontSize: '24px', color: '#374151' }}
                        onClick={() => {
                          setQinfo({
                            description: `อินเตอร์เน็ตเป็นสิ่งอำนวยความสะดวกที่จำเป็นมากที่สุดอย่างหนึ่งในแต่ละโรงเรียน แต่ก็ยังมีบางโรงเรียนที่ไม่มีการเข้าถึงอินเตอร์เน็ต ซึ่งโรงเรียนที่ใช้งานอินเตอร์เน็ตนั้นจะถูกจำแนกออกตามประเภทของอินเตอร์เน็ต เช่น IPSTAR ADSL LEASEDLINE และอื่น ๆ`,
                            refs: ['ข้อมูลโรงเรียน DMC ปีการศึกษา 2563 - 2566']
                          });
                          setIsModalQinfoVisible(true)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full h-[1px] bg-gray-200 m-1"></div>
                <Chart
                  options={{
                    legend_enabled: false,
                    title: '',
                    type: 'bar',
                    categories: school?.internet ? school.internet[0].data.map(x => x.name) : []
                  }}
                  containerProps={{ style: { height: "310px" } }}
                  style={{ height: '330px' }}
                  click={({ e, category, name }) => {
                    showModal()
                    setColumns(confixColumnsTable.target_resources_internet)
                    setDataTable(mapDataTable('table3', dataSummaryAllyear, filter.year))
                    setTitle('ข้อมูลจำนวนโรงเรียนที่ใช้งานอินเตอร์เน็ตแต่ละประเภท ปีการศึกษา ' + filter.year)
                  }}
                  data={school.internet}
                />
              </div>
            </div>




            <div className="col-span-12">
              <div className="shadow-card w-full bg-[#FFFFFF] rounded-xl px-2 py-4" >
                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                  <div className='flex flex-col'>
                    <div className="text-2xl">ข้อมูลแหล่งน้ำ</div>
                    <div className="text-md">ข้อมูลจำนวนโรงเรียนที่ใช้แหล่งน้ำแต่ละประเภท</div>
                  </div>
                  <div style={{ width: '72px', justifyContent: 'space-around', flexDirection: 'row', display: 'flex' }} >
                    <div style={{ width: '24px', justifyContent: 'space-around' }} >
                      <TableOutlined style={{ fontSize: '24px', color: '#374151' }} onClick={() => {
                        showModal()
                        setColumns(confixColumnsTable.target_resources_water)
                        setDataTable(mapDataTable('table4', dataSummaryAllyear, filter.year))
                        setTitle('ข้อมูลจำนวนโรงเรียนที่ใช้แหล่งน้ำแต่ละประเภท ปีการศึกษา ' + filter.year)
                      }} />
                    </div>
                    <div style={{ width: '24px', justifyContent: 'space-around' }} >
                      <InfoCircleOutlined style={{ fontSize: '24px', color: '#374151' }}
                        onClick={() => {
                          setQinfo({
                            description: ` แหล่งน้ำใช้ของแต่ละโรงเรียนก็สามารถจำแนกตามประเภทได้เช่นกัน ไม่ว่าจะเป็นประปาโรงเรียน ประปาหมู่บ้าน ประปาบาดาล และอื่น ๆ`,
                            refs: ['ข้อมูลโรงเรียน DMC ปีการศึกษา 2563 - 2566']
                          });
                          setIsModalQinfoVisible(true)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full h-[1px] bg-gray-200 m-1"></div>
                <Chart
                  options={{
                    legend_enabled: false,
                    title: '',
                    type: 'bar',
                    categories: school?.water ? school.water[0].data.map(x => x.name) : []
                  }}
                  containerProps={{ style: { height: "310px" } }}
                  style={{ height: '330px' }}
                  click={({ e, category, name }) => {
                    showModal()
                    setColumns(confixColumnsTable.target_resources_water)
                    setDataTable(mapDataTable('table4', dataSummaryAllyear, filter.year))
                    setTitle('ข้อมูลจำนวนโรงเรียนที่ใช้แหล่งน้ำแต่ละประเภท ปีการศึกษา ' + filter.year)
                  }}
                  data={school.water}
                />
              </div>
            </div>

            <div className="col-span-12">
              <div className="shadow-card w-full bg-[#FFFFFF] rounded-xl px-2 py-4 flex flex-col" >
                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                  <div className='flex flex-col'>
                    <div className="text-2xl">ปัญหาสภาพแวดล้อม</div>
                  </div>
                  <div style={{ width: '72px', justifyContent: 'space-around', flexDirection: 'row', display: 'flex' }} >
                    <div style={{ width: '24px', justifyContent: 'space-around' }} >
                      <InfoCircleOutlined style={{ fontSize: '24px', color: '#374151' }}
                        onClick={() => {
                          setQinfo({
                            description: 'ในแต่ละโรงเรียนต่างก็มีการพบเจอกับปัญหาในแต่ละด้านที่แตกต่างกันไป ซึ่งปัญหาเหล่านั้นล้วนเกิดมาจากสภาพแวดล้อมในบริเวณของโรงเรียนทั้งสิ้น ไม่ว่าจะเป็น ปัญหาเสียงจากการจราจร ปัญหาอากาศจากโรงงาน/สารเคมี ปัญหาน้ำจากขยะ/สิ่งปฏิกูล เป็นต้น',
                            refs: ['ข้อมูลโรงเรียน DMC ปีการศึกษา 2563 - 2566']
                          });
                          setIsModalQinfoVisible(true)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full h-[1px] bg-gray-200 m-1"></div>
                <div className='w-full flex flex-row justify-around'>
                  <ProgressProbView
                    agricultural={school.agricultural_chemistry_air_problem}
                    chemical={school.factory_air_problem}
                    traffic={school.traffic_air_problem}
                    sewage={school.garbage_air_problem}
                    total={
                      school.env_problem_total
                    }
                    category="อากาศ"
                    title="ปัญหาสภาพอากาศ"
                  />

                  <ProgressProbView
                    agricultural={school.agricultural_chemistry_water_problem}
                    chemical={school.factory_water_problem}
                    traffic={school.traffic_water_problem}
                    sewage={school.garbage_water_problem}
                    total={
                      school.env_problem_total
                    }
                    category="น้ำ"
                    title="ปัญหาสภาพน้ำ"
                  />

                  <ProgressProbView
                    agricultural={school.agricultural_chemistry_noise_problem}
                    chemical={school.factory_noise_problem}
                    traffic={school.traffic_noise_problem}
                    sewage={school.garbage_noise_problem}
                    total={
                      school.env_problem_total
                    }
                    category="เสียง"
                    title="ปัญหาสภาพเสียง"
                  />
                </div>
              </div>
            </div>
          </div>
          <RefDataDate
            title=""
            subjectTitle="*ประมวลผล ณ เดือนสิงหาคม 2567"
          />

        </div >
      </div >
    </Layout >
  );
}
